import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DatePicker, Select } from '@shopify/polaris';
import { format } from 'date-fns';
import TimeZones from 'timezones.json';

import TimePicker from '../../TimePicker';

interface Range {
  start: Date;
  end: Date;
}

function Component({ data, setData }: RecipeRuleProps): JSX.Element {
  const refTimeZones = useRef(TimeZones);
  const now = new Date();
  const [{ month, year }, setDate] = useState({
    month: now.getMonth(),
    year: now.getFullYear(),
  });

  let { start, end } = data;
  const { timezone, timezoneTitle, startTime, endTime } = data;

  if (typeof start === 'string') {
    start = new Date(start);
  }
  if (typeof end === 'string') {
    end = new Date(end);
  }

  const [selectedDates, setSelectedDates] = useState({
    start,
    end,
  });

  const handleMonthChange = useCallback(
    (nextMonth, nextYear) => setDate({ month: nextMonth, year: nextYear }),
    [],
  );

  const handleDateChange = useCallback(
    (newDateRange: Range) => {
      setSelectedDates(newDateRange);
      setData((nextData: RecipeRuleData) => ({
        ...nextData,
        ...newDateRange,
      }));
    },
    [setData],
  );

  const handleStartTimeChange = (time: string) => {
    setData((nextData: RecipeRuleData) => ({
      ...nextData,
      startTime: time,
    }));
  };

  const handleEndTimeChange = (time: string) => {
    setData((nextData: RecipeRuleData) => ({
      ...nextData,
      endTime: time,
    }));
  };

  const setDefaultTime = useCallback(() => {
    if (!startTime && !endTime) {
      setData((nextData: RecipeRuleData) => ({
        ...nextData,
        startTime: '00:00',
        endTime: '23:59',
      }));
    }
  }, [setData, startTime, endTime]);

  const setDefaultTimezone = useCallback(() => {
    if (!timezone && !timezoneTitle) {
      // Attempt to find the user's local timezone and select it by default
      const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      for (let i = 0; i < refTimeZones.current.length; i += 1) {
        if (refTimeZones.current[i].utc.includes(currentTimezone)) {
          setData((nextData: RecipeRuleData) => ({
            ...nextData,
            timezone: refTimeZones.current[i].utc[0],
            timezoneTitle: refTimeZones.current[i].text,
          }));
          break;
        }
      }
    }
  }, [setData, timezone, timezoneTitle]);

  useEffect(() => {
    setDefaultTimezone();
    setDefaultTime();
  }, [setDefaultTimezone, setDefaultTime]);

  return (
    <>
      <div className="rb-pb-5">
        the date at your visitor&apos;s location is within the following range
      </div>
      <div className="rb-bg-white-bg hover:rb-bg-hover-bg rb-p-4 rb-rounded-lg rb-drop-shadow-md rb-border rb-border-date-border">
        <DatePicker
          month={month}
          year={year}
          onChange={handleDateChange}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          multiMonth
          allowRange
        />
      </div>
      <div className="rb-flex rb-space-x-8 rb-mt-4">
        <div className="rb-flex rb-space-x-2 rb-items-center">
          <div>
            <span className="rb-font-bold">From: </span>
            <span>{format(selectedDates.start, 'MMM. dd, yyyy')}</span>
          </div>
          <TimePicker
            key="startTime"
            time={startTime}
            setTime={handleStartTimeChange}
          />
        </div>
        <div className="rb-flex rb-space-x-2 rb-items-center">
          <div>
            <span className="rb-font-bold">To: </span>
            <span>{format(selectedDates.end, 'MMM. dd, yyyy')}</span>
          </div>
          <TimePicker
            key="endTime"
            time={endTime}
            setTime={handleEndTimeChange}
          />
        </div>
      </div>
      <div className="rb-mt-4">
        <Select
          label=""
          options={refTimeZones.current.map((tz, key) => ({
            label: tz.text,
            value: tz.text,
            key,
          }))}
          onChange={(nextTimezoneTitle: string) => {
            // grab the full timezone object with corresponding title to acquire utc for api
            const timezoneObj = refTimeZones.current.filter(
              tz => tz.text === nextTimezoneTitle,
            );
            setData((nextData: RecipeRuleData) => ({
              ...nextData,
              timezone: timezoneObj[0].utc[0],
              timezoneTitle: nextTimezoneTitle,
            }));
          }}
          value={timezoneTitle}
        />
      </div>
    </>
  );
}

export default class DateRangeRule implements RecipeRule {
  id = '';

  name = 'Date range';

  schema = {
    start: new Date(),
    end: new Date(),
  };

  helpCenterArticle = 'date-range';

  render = (
    data: RecipeRuleData,
    setData: RecipeRuleDataSetter,
  ): JSX.Element => <Component data={data} setData={setData} />;
}
