import React from 'react';

import { FormLayout, Select } from '@shopify/polaris';

function Component({ data, setData }: RecipeRuleProps): JSX.Element {
  const { forecastType, weatherType, forecastRange } = data;

  return (
    <>
      <div className="rb-pb-5">the weather at the visitor&apos;s location</div>
      <FormLayout>
        <FormLayout.Group condensed>
          <Select
            label=""
            options={[
              { label: 'is currently', value: 'current' },
              { label: 'is forecasted to be', value: 'forecast' },
            ]}
            onChange={nextForecastType =>
              setData((nextData: RecipeRuleData) => ({
                ...nextData,
                forecastType: nextForecastType,
              }))
            }
            value={forecastType}
          />
          <Select
            label=""
            options={[
              { label: 'sunny', value: 'sunny' },
              { label: 'cloudy', value: 'cloudy' },
              { label: 'raining', value: 'rain' },
              { label: 'snowing', value: 'snow' },
              { label: 'foggy', value: 'fog' },
              { label: 'thunder', value: 'thunder' },
            ]}
            onChange={nextWeatherType =>
              setData((nextData: RecipeRuleData) => ({
                ...nextData,
                weatherType: nextWeatherType,
              }))
            }
            value={weatherType}
          />
          {data.forecastType === 'forecast' && (
            <Select
              label=""
              options={[
                { label: 'tomorrow', value: 'tomorrow' },
                { label: 'this week', value: 'thisWeek' },
                { label: 'next 7 days', value: 'nextSevenDays' },
              ]}
              onChange={nextForecastRange =>
                setData((nextData: RecipeRuleData) => ({
                  ...nextData,
                  forecastRange: nextForecastRange,
                }))
              }
              value={forecastRange}
            />
          )}
        </FormLayout.Group>
      </FormLayout>
    </>
  );
}

export default class WeatherRule implements RecipeRule {
  id = '';

  name = 'Weather';

  helpCenterArticle = 'weather';

  schema = {
    forecastType: 'current',
    weatherType: 'sunny',
    forecastRange: 'tomorrow',
  };

  render = (
    data: RecipeRuleData,
    setData: RecipeRuleDataSetter,
  ): JSX.Element => <Component data={data} setData={setData} />;
}
