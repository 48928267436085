import React, { useCallback, useState } from 'react';

import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import { Spinner, Stack, Tag, TextField } from '@shopify/polaris';

import HelpText from '../../HelpText';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
const GOOGLE_MAPS_LIBS = ['places'] as 'places'[];

type AddressComponents = {
  countryCode?: string;
  countryName?: string;
  cityName?: string;
  adminAreas: string[];
};

type Location = {
  placeId?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  components: AddressComponents;
};

function mapAddressComponents(
  components: google.maps.GeocoderAddressComponent[],
) {
  return components.reduce(
    (acc, component) => {
      if (component.types.includes('country')) {
        acc.countryCode = component.short_name;
        acc.countryName = component.long_name;
      } else if (component.types.includes('locality')) {
        acc.cityName = component.short_name;
      } else if (
        component.types.some(type =>
          type.startsWith('administrative_area_level_'),
        )
      ) {
        acc.adminAreas.push(component.long_name);
      }
      return acc;
    },
    { adminAreas: [] } as AddressComponents,
  );
}

const isPlaceTypeSupported = (place: google.maps.places.PlaceResult): boolean =>
  !!place.types &&
  place.types.some(
    type =>
      ['country', 'locality'].includes(type) ||
      type.startsWith('administrative_area_level_'),
  );

function Component({ data, setData }: RecipeRuleProps): JSX.Element {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBS,
  });

  const [searchBox, setSearchBox] = useState(
    undefined as google.maps.places.SearchBox | null | undefined,
  );
  const [userInput, setUserInput] = useState<string | undefined>('');
  const [isSupportedAddress, setIsSupportedAddress] = useState<boolean>(true);

  const handlePlaceChanged = useCallback(() => {
    if (!searchBox) {
      return;
    }

    const places = searchBox.getPlaces();
    if (!places || places.length === 0) {
      return;
    }

    const place = places[0];
    const supported = isPlaceTypeSupported(place);
    setIsSupportedAddress(supported);
    if (!supported) {
      return;
    }

    setData(s => {
      const currentLocations = s.locations ?? [];

      // Handle updating old style location
      const oldLocation: Location[] = s.placeId
        ? [
            {
              placeId: s.placeId,
              address: s.address,
              latitude: s.latitude,
              longitude: s.longitude,
              components: s.components,
            },
          ]
        : [];

      return {
        id: s.id,
        typeId: s.typeId,
        locations: [
          ...oldLocation,
          ...currentLocations,
          {
            placeId: place.place_id,
            address: place.formatted_address,
            latitude: place?.geometry?.location?.lat(),
            longitude: place?.geometry?.location?.lng(),

            components:
              place.address_components &&
              mapAddressComponents(place.address_components),
          } as Location,
        ],
      };
    });
    setUserInput('');
  }, [searchBox, setData]);

  const handleRemoveTag = useCallback(
    (location: Location) => {
      setData(s => ({
        ...s,
        locations: (s.locations as Location[]).filter(
          curr => curr.placeId !== location.placeId,
        ),
      }));
    },
    [setData],
  );

  const renderRemoveTags = useCallback(() => {
    if (!data?.locations) return null;

    const locations = [];
    for (let i = 0; i < data.locations.length; i += 1) {
      const location = data.locations[i];
      if (i > 0) {
        locations.push(<span>or</span>);
      }
      locations.push(
        <Tag
          key={`location-${location.placeId}`}
          onRemove={() => handleRemoveTag(location)}
        >
          {location.address}
        </Tag>,
      );
    }

    return locations;
  }, [handleRemoveTag, data]);

  return (
    <>
      {!isLoaded && <Spinner size="small" />}
      {isLoaded && (
        <>
          <div className="rb-flex rb-items-center rb-space-x-2 rb-pb-5">
            <span>your visitor is from any of the following locations</span>
            <HelpText text="Depending on your visitor's current location, we will match to the entered country, region, or city." />
          </div>
          <StandaloneSearchBox
            onLoad={setSearchBox}
            onPlacesChanged={handlePlaceChanged}
          >
            <TextField
              autoComplete="off"
              label=""
              onChange={setUserInput}
              value={userInput}
              placeholder="Country, region, or city."
              clearButton
              onClearButtonClick={() => setUserInput('')}
              error={
                isSupportedAddress
                  ? false
                  : 'Please enter a country, region, or city.'
              }
            />
          </StandaloneSearchBox>
          <div className="rb-pt-3">
            <Stack alignment="center">{renderRemoveTags()}</Stack>
          </div>
        </>
      )}
    </>
  );
}

export default class LocationRule implements RecipeRule {
  id = '';

  name = 'Geographic location';

  helpCenterArticle = 'geographic-location';

  schema = {
    locations: [],
  } as {
    locations: {
      placeId: string | undefined;
      address: string | undefined;
      latitude: number | undefined;
      longitude: number | undefined;
      components: AddressComponents;
    }[];
  };

  render = (
    data: RecipeRuleData,
    setData: RecipeRuleDataSetter,
  ): JSX.Element => (
    <div>
      <Component data={data} setData={setData} />
    </div>
  );
}
