import React, { useEffect } from 'react';

import { Select } from '@shopify/polaris';

import { MultiAutoCombobox } from './utils/MultiAutoCombobox';
import { useIntegrations } from '../../../api';
import { capitalizeFirstLetter } from '../../../common/utils';
import { FlexLoader } from '../../utils/common-components';

type ProviderOption = {
  value: string;
  label: string;
};

function Component({ data, setData }: RecipeRuleProps): JSX.Element {
  const { integrations } = useIntegrations();
  const [providerOptions, setProviderOptions] = React.useState<
    ProviderOption[]
  >([]);
  const { typeId } = data;

  useEffect(() => {
    if (integrations) {
      const integrationOptions = integrations.map(integration => ({
        value: integration.type,
        label: capitalizeFirstLetter(integration.type),
      }));
      const defaultOptions = [
        {
          value: 'shopify',
          label: 'Shopify',
        },
      ];

      setProviderOptions([...integrationOptions, ...defaultOptions]);
    }
  }, [integrations]);

  const handleProviderChange = (value: string) => {
    setData((prevData: RecipeRuleData) => ({
      ...prevData,
      provider: value,
      segments: [],
    }));
  };

  if (integrations === undefined) {
    return <FlexLoader />;
  }
  const defaultProvider =
    integrations.length > 0 ? integrations[0].type : 'shopify';
  const dataProvider = data.provider ? data.provider : defaultProvider;

  return (
    <>
      <div className="rb-pb-5" data-type={typeId}>
        your visitor falls under any of the following Customer segments
      </div>
      <div className="rb-flex">
        {integrations.length > 0 ? (
          <div className="rb-pr-3.6">
            <Select
              label=""
              labelHidden
              options={providerOptions}
              onChange={handleProviderChange}
              value={data.provider ? data.provider : ''}
            />
          </div>
        ) : null}
        <div className="rb-grow">
          <MultiAutoCombobox
            data={{ ...data, provider: dataProvider }}
            setData={setData}
          />
        </div>
      </div>
    </>
  );
}

export default class CustomerSegmentsRule implements RecipeRule {
  id = '';

  name = 'Customer segments';

  schema = {
    provider: '',
    segments: [],
  };

  helpCenterArticle = 'customer-segments';

  render = (
    data: RecipeRuleData,
    setData: RecipeRuleDataSetter,
  ): JSX.Element => <Component data={data} setData={setData} />;
}
