import CustomerSegmentsRule from './rules/CustomerSegments';
import DateRangeRule from './rules/DateRange';
import LocationRule from './rules/Location';
import RefererRule from './rules/Referer';
import UTMParametersRule from './rules/UTMParameters';
import WeatherRule from './rules/Weather';

export const RULE_TYPES = [
  {
    typeId: 'customerSegments',
    title: 'Customer Segments',
    description: "Your customer's segment match.",
    singleton: false,
    classType: CustomerSegmentsRule,
  },
  {
    typeId: 'dateRange',
    title: 'Date range',
    description: "Current date at your visitor's location.",
    singleton: true,
    classType: DateRangeRule,
  },
  {
    typeId: 'location',
    title: 'Geographic location',
    description: "Your visitor's current location.",
    singleton: true,
    classType: LocationRule,
  },
  {
    typeId: 'referer',
    title: 'Referrer',
    description: "The referring domain of your visitor's enterence page.",
    singleton: true,
    classType: RefererRule,
  },
  {
    typeId: 'utm',
    title: 'UTM parameters',
    description:
      'UTM parameters present in the URL. These parameters are used for marketing analytics purposes.',
    singleton: false,
    classType: UTMParametersRule,
  },
  {
    typeId: 'weather',
    title: 'Weather',
    description: "Current and forecasted weather at your visitor's location.",
    singleton: false,
    classType: WeatherRule,
  },
];

export type AddRuleModalProps = {
  active: boolean;
  setActive: (active: boolean) => void;
  rules: RecipeRule[];
  onSubmit: (typeId: string) => void;
};

export type RecipeRulesProps = {
  recipeData: Recipe;
  setRecipeData: (recipeData: Recipe) => void;
};
