import { useCallback, useEffect, useState } from 'react';

import {
  Banner,
  Link,
  Loading,
  ContextualSaveBar,
  SelectGroup,
  Button,
} from '@shopify/polaris';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import PageNotFound from './PageNotFound';
import { useFetchJson, useRecipe, useTemplates, useOptions } from '../api';
import { initialTemplates } from '../common/utils';
import RecipeAnalytics from '../components/Analytics/RecipeAnalytics';
import DeleteRecipeModal from '../components/DeleteRecipeModal';
import PageHeader from '../components/PageHeader';
import PopoverMenu from '../components/PopoverMenu';
import RecipeRules from '../components/RecipeRules';
import RecipeVariations from '../components/RecipeVariations';
import RenameRecipeModal from '../components/RenameRecipeModal';
import SubmenuNavigator from '../components/SubmenuNavigator';
import { useShopStatus } from '../graph';

const INITIAL_TEMPLATE = 'templates/index.json';

const getCurrentTemplateFromParam = () => {
  const currentTemplate = new URLSearchParams(window.location.search).get(
    'currentTemplate',
  );
  return currentTemplate || INITIAL_TEMPLATE;
};

export default function RecipeDetails(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  if (!id) {
    throw new Error('invalid recipe id');
  }

  const { recipe, error, reload: reloadRecipeData } = useRecipe(id);
  const { storeUrl } = useShopStatus();
  const { templates } = useTemplates();
  const [templatesArr, setTemplatesArr] = useState<SelectGroup[]>([]);
  const [currentTemplate, setCurrentTemplate] = useState(
    getCurrentTemplateFromParam(),
  );
  const [, setSearchParams] = useSearchParams();
  const [recipeData, setRecipeData] = useState(null as Recipe | null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [saveChangesLoading, setSaveChangesLoading] = useState(false);
  const [deleteRecipeModalVisible, setDeleteRecipeModalVisible] =
    useState(false);
  const [renameRecipeModalVisible, setRenameRecipeModalVisible] =
    useState(false);
  const jsonFetch = useFetchJson();

  const { viewedABTesting } = useOptions();
  const [dismissedABTesting, setDismissedABTesting] = useState(false);

  useEffect(() => {
    if (dismissedABTesting) {
      jsonFetch('post', '/session/options', { viewedABTesting: true });
    }
  }, [dismissedABTesting, jsonFetch]);

  const updateRecipeData = useCallback(
    (data: Recipe) => {
      setRecipeData(data);
      setHasUnsavedChanges(true);
    },
    [setRecipeData, setHasUnsavedChanges],
  );

  const saveChanges = useCallback(async () => {
    if (!recipeData) {
      return;
    }

    setSaveChangesLoading(true);

    try {
      await jsonFetch('post', `/recipes/${encodeURIComponent(id)}`, {
        ...recipeData,
      });
      await reloadRecipeData();
    } catch (err) {
      throw new Error("can't save recipe changes");
    }

    setSaveChangesLoading(false);
    setHasUnsavedChanges(false);
  }, [recipeData, jsonFetch, setSaveChangesLoading, id, reloadRecipeData]);

  const discardChanges = useCallback(() => {
    if (recipe) {
      setRecipeData(JSON.parse(JSON.stringify(recipe)));
    }

    setHasUnsavedChanges(false);
  }, [recipe, setRecipeData]);

  useEffect(() => {
    if (recipe) {
      setRecipeData(JSON.parse(JSON.stringify(recipe)));
    }
  }, [recipe, setRecipeData]);

  const handlePreview = useCallback(() => {
    const url = storeUrl as string;
    window.open(
      `${url}?recipe=${encodeURIComponent(id)}&ctx=${Math.random().toString()}`,
      '_blank',
    );
  }, [storeUrl, id]);

  useEffect(() => {
    if (templates && templates.length > 0) {
      setTemplatesArr(initialTemplates(templates));
    }
  }, [templates]);

  useEffect(() => {
    setSearchParams({ currentTemplate });
  }, [currentTemplate, setSearchParams]);

  if (recipe?.message) {
    return <PageNotFound />;
  }

  if (!recipe || !recipeData || !templates) {
    return <Loading />;
  }

  const options = [
    {
      label: 'Content Variations',
      path: 'variations',
      child: (
        <RecipeVariations
          recipeData={recipeData!}
          setRecipeData={updateRecipeData}
          templates={templatesArr}
          currentTemplate={currentTemplate}
          setCurrentTemplate={setCurrentTemplate}
        />
      ),
    },
    {
      label: 'Rules',
      path: 'rules',
      child: (
        <RecipeRules
          recipeData={recipeData!}
          setRecipeData={updateRecipeData}
        />
      ),
    },
    {
      label: 'Analytics',
      path: 'analytics',
      child: (
        <RecipeAnalytics
          templates={templatesArr}
          currentTemplate={currentTemplate}
          setCurrentTemplate={setCurrentTemplate}
          recipeData={recipeData!}
        />
      ),
    },
  ];

  const banner =
    viewedABTesting || dismissedABTesting ? undefined : (
      <Banner
        onDismiss={() => {
          setDismissedABTesting(true);
        }}
        title="A/B testing 🚀"
        status="info"
      >
        <p>Select two or more variations in a section to enable A/B testing.</p>
        <Link url="https://docs.context.relevantbits.com/ab-testing">
          Learn more
        </Link>
      </Banner>
    );
  return (
    <>
      <div>
        <PageHeader
          title={recipe.name}
          subtitle={recipe.desc}
          whiteBackground
          divider
          morePadding
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Button primary onClick={handlePreview} size="large">
              Preview
            </Button>
            <div style={{ marginLeft: '16px' }}>
              <PopoverMenu
                onRename={() => setRenameRecipeModalVisible(true)}
                onDelete={() => setDeleteRecipeModalVisible(true)}
              />
            </div>
          </div>
        </PageHeader>
        {hasUnsavedChanges && (
          <ContextualSaveBar
            message="You have unsaved changes."
            alignContentFlush
            fullWidth
            saveAction={{
              onAction: saveChanges,
              loading: saveChangesLoading,
              disabled: false,
            }}
            discardAction={{
              onAction: discardChanges,
            }}
          />
        )}
        {error && (
          <Banner
            title="Server error."
            action={{ content: 'Reload' }}
            status="critical"
          >
            <p>There was an error while loading data from the server.</p>
          </Banner>
        )}
        {recipeData && (
          <>
            <DeleteRecipeModal
              recipe={recipeData}
              active={deleteRecipeModalVisible}
              setActive={setDeleteRecipeModalVisible}
              onDeleted={() => navigate('/')}
            />
            <RenameRecipeModal
              recipe={recipeData}
              active={renameRecipeModalVisible}
              setActive={setRenameRecipeModalVisible}
              onRenamed={reloadRecipeData}
            />
          </>
        )}
      </div>
      <SubmenuNavigator
        title="Recipe details"
        options={options}
        banner={banner}
      />
    </>
  );
}
